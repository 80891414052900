import {
  Component,
  OnInit,
  Inject,
  Output,
  EventEmitter,
  AfterContentChecked,
  ChangeDetectorRef,
} from '@angular/core';
import {
  DialogCloseMessage,
  DiplomaticId,
  ForeignVisitor,
  Visa,
} from '@app/shared/models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VisaService } from '@app/shared/services/visa.service';
import { take } from 'rxjs/operators';
import { FvService } from '@app/shared/services';
import { Subscription } from 'rxjs';
import { DialogData } from '../fvview-diplomatic-passport.component';

@Component({
  selector: 'app-diplomatic-passport-dialog',
  templateUrl: './diplomatic-passport-dialog.component.html',
})
export class DiplomaticPassportDialogComponent implements OnInit {
  documentId: DiplomaticId = {
    documentType: 'DIPLOMAT',
  };
  @Output() emitSave: EventEmitter<DiplomaticId> =
    new EventEmitter<DiplomaticId>();
  busy: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DiplomaticId,
    public dialogRef: MatDialogRef<DiplomaticPassportDialogComponent>,
    public fvService: FvService,
    private changeDetect: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.documentId = this.data;
  }

  //Supressing "ExpressionChangedAfterItHasBeenCheckedError" occurring due to diplomaticForm in VisaForm Component values changing
  ngAfterContentChecked(): void {
    this.changeDetect.detectChanges();
  }

  submitDiplomaticId() {
    this.busy = true;
    this.documentId.documentType = 'DIPLOMAT';
    !this.documentId.id
      ? this.fvService
          .addDocIdToFv(this.documentId.foreignVisitor?.id!, [this.documentId])
          .pipe(take(1))
          .subscribe(
            (result) => {
              this.onClose(result as DiplomaticId);
            },
            (err) => (this.busy = false)
          )
      : this.fvService
          .updateDocId(this.documentId.foreignVisitor?.id!, this.documentId!)
          .pipe(take(1))
          .subscribe(
            (result: ForeignVisitor) => {
              this.onClose(result as DiplomaticId);
            },
            (err) => (this.busy = false)
          );
  }

  onClose(diplomaticId: DiplomaticId) {
    this.dialogRef.close(diplomaticId);
  }
}
