import { Directive, ElementRef, Input, OnChanges, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { isValid, alpha3ToAlpha2, getName } from 'i18n-iso-countries';

export enum prefix {
  square = 'flag-sq-',
  rectangle = 'flag-',
}
/**
 * An attribute directive used to render a flag with the mat-icon
 * directive.
 *
 * ## Exapmles:
 *
 * ### With 2 character Codes
 *
 * ```
 *   <mat-icon [flagIcon]="'US'"></mat-icon>
 *   <mat-icon [flagIcon]="'us'"></mat-icon>
 *   <mat-icon [flagIcon]="'US'" [isSquare]="true"></mat-icon>
 *   <mat-icon [flagIcon]="'us'" [isSquare]="true"></mat-icon>
 * ```
 * ### With 3 character Codes
 *
 * ```
 *   <mat-icon [flagIcon]="'USA'"></mat-icon>
 *   <mat-icon [flagIcon]="'usa'"></mat-icon>
 *   <mat-icon [flagIcon]="'USA'" [isSquare]="true"></mat-icon>
 *   <mat-icon [flagIcon]="'usa'" [isSquare]="true"></mat-icon>
 * ```
 */

@Directive({
  selector: 'mat-icon[flagIcon],mat-icon[flagSVGIcon]',
})
export class FlagIconDirective implements OnInit, OnChanges {
  _prefix = prefix.rectangle;
  _flag = 'xx';
  @Input() isSquare: boolean = false;
  @Input() flagIcon: string;
  @Input() flagSVGIcon: string = '';

  constructor(
    private readonly _matIcon: MatIcon,
    private elRef: ElementRef<HTMLElement>
  ) {}

  ngOnInit() {
    this.refreshIcon();
  }
  ngOnChanges() {
    this.refreshIcon();
  }

  refreshIcon() {
    if (this.flagSVGIcon?.length) this.setSVGIcon();
    else this.setFontIcon();
  }

  setSVGIcon() {
    if (this.isSquare) this._prefix = prefix.square;
    this._flag = this.getFlagCode(this.flagSVGIcon);
    this._matIcon.fontSet = '';
    this._matIcon.fontIcon = '';
    this._matIcon.svgIcon = [this._prefix, this._flag].join('');
  }

  setFontIcon() {
    const flagClasses = [];
    if (this.isSquare) flagClasses.push('fis');
    if (this.flagIcon) {
      this._flag = this.getFlagCode(this.flagIcon);
    }
    this._matIcon.svgIcon = '';
    flagClasses.push(`fi-${this._flag.toLowerCase()}`);
    this._matIcon.fontSet = 'flag';
    this._matIcon.fontIcon = flagClasses.join(' ');
  }

  getFlagCode(flag: string) {
    const isAlpha2 = isValid(flag) && flag.length == 2;
    const isAlpha3 = isValid(flag) && flag.length == 3;
    let flagCode;
    if (isAlpha2 || isAlpha3) {
      flagCode = isAlpha3 ? alpha3ToAlpha2(flag.toLocaleUpperCase()) : flag;
    }
    return flagCode || 'XX';
  }

  get countryName() {
    return getName(this._flag, 'en') || this.flagIcon || this.flagSVGIcon;
  }
}
