<mat-table #innerTables [dataSource]="foreignAccessRequestLocations">
  <ng-container [matColumnDef]="'startDate'">
    <mat-header-cell class="justify-content-center" *matHeaderCellDef>
      Start Date
    </mat-header-cell>
    <mat-cell class="justify-content-center" *matCellDef="let location">
      {{ location.startDate | date: "mediumDate" }}
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="'endDate'">
    <mat-header-cell class="justify-content-center" *matHeaderCellDef>
      End Date
    </mat-header-cell>
    <mat-cell class="justify-content-center" *matCellDef="let location">
      {{ location.endDate | date: "mediumDate" | default }}
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="'locationName'">
    <mat-header-cell class="justify-content-center" *matHeaderCellDef>
      Location Name
    </mat-header-cell>
    <mat-cell
      class="justify-content-center"
      style="line-break: anywhere"
      *matCellDef="let location"
    >
      {{ location.organizationLocation?.name | titlecase | default }}
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="'locationAddress'">
    <mat-header-cell class="justify-content-center" *matHeaderCellDef>
      Address
    </mat-header-cell>
    <mat-cell class="justify-content-center" *matCellDef="let location">
      <div>
        <div>
          {{ location.organizationLocation?.address | address: "street" }}
        </div>
        <div>
          {{ location.organizationLocation?.address | address: "cityState" }}
        </div>
        <div>
          {{
            location.organizationLocation?.address?.countryCode
              | country: "alpha3"
          }}
          {{ location.organizationLocation?.address?.postalCode }}
        </div>
      </div>
      <div
        *ngIf="
          !location.organizationLocation?.address ||
          !addressFieldsHaveData(location.organizationLocation?.address)
        "
      >
        N/A
      </div>
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="'orgShortName'">
    <mat-header-cell class="justify-content-center" *matHeaderCellDef>
      Organization
    </mat-header-cell>
    <mat-cell class="justify-content-center" *matCellDef="let location">
      {{ location.organizationLocation?.organization?.shortName | default }}
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="innerDisplayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: innerDisplayedColumns"></mat-row>
</mat-table>
