<mat-card appearance="outlined" class="d-flex flex-column associated-fvs">
  <mat-card-header class="header-container">
    <div class="d-flex justify-content-between">
      <div>
        <mat-card-title>
          Foreign Nationals ({{ fvs?.totalElements || 0 }})
        </mat-card-title>
      </div>
      <div>
        <ng-container *appIfRoles="FVRescreenRoles">
          <span
            [matTooltip]="
              !isRescreenable
                ? 'Rescreen Unavailable - No Eligible Foreign Nationals'
                : ''
            "
          >
            <button
              *ngIf="
                (fvs && fvs.content && fvs.content.length > 0) ||
                filterParams.result
              "
              mat-button
              [disabled]="!isRescreenable"
              color="primary"
              matTooltip="Rescreen All Eligible Foreign Nationals"
              (click)="openConfirmationDialog()"
            >
              <div class="d-flex align-items-center gap-1">
                <mat-icon svgIcon="send-circle-outline"></mat-icon>
                <span>Rescreen</span>
              </div>
            </button>
          </span>
        </ng-container>
        <button
          *ngIf="
            isEditable &&
            ((fvs && fvs.content && fvs.content.length > 0) ||
              filterParams.result)
          "
          mat-button
          color="primary"
          matTooltip="Add Foreign Nationals Menu"
          #addFnTrigger="matMenuTrigger"
          [matMenuTriggerFor]="addFnMenu"
        >
          <div class="d-flex align-items-center gap-1">
            <mat-icon svgIcon="plus-circle-outline"></mat-icon>
            <span>Add</span>
          </div>
        </button>
      </div>
      <mat-menu #addFnMenu>
        <button
          id="add-available-fn"
          mat-menu-item
          (click)="onAdd()"
          matTooltip="Add a Foreign National that is currently available"
          matTooltipPosition="after"
        >
          <mat-icon svgIcon="content-copy"></mat-icon>
          <span> Add Available </span>
        </button>
        <button
          *ngIf="isSameOrg"
          id="create-fn-far"
          mat-menu-item
          (click)="onAddNew()"
          matTooltip="Create a new Foreign National to add to the FAR"
          matTooltipPosition="after"
        >
          <mat-icon svgIcon="file-document-plus-outline"></mat-icon>
          <span>Add New</span>
        </button>
        <button
          id="bulk-upload-fn-far"
          mat-menu-item
          [appBulkUpload]="'FarFV'"
          [farId]="[farId]"
          (appBulkUploaded)="onBulkUpload()"
          matTooltip="Bulk Upload new Foreign Nationals to add to the FAR"
          matTooltipPosition="after"
        >
          <mat-icon svgIcon="file-document-multiple-outline"></mat-icon>
          <span>Bulk Upload</span>
        </button>
        <button
          *ngIf="isSameOrg"
          id="add-group-far"
          mat-menu-item
          (click)="onAddGroup()"
          matTooltip="Add existing Foreign National Group to the FAR"
          matTooltipPosition="after"
        >
          <mat-icon svgIcon="select-group"></mat-icon>
          <span>Add Group</span>
        </button>
      </mat-menu>
    </div>
    <div class="filter-container border-bottom">
      <ng-container
        *ngIf="
          (fvs && fvs.content && fvs.content.length > 0) || filterParams.result
        "
      >
        <div class="filter-title">Quick Filter:</div>
        <form class="filter-selection" #roleForm="ngForm">
          <mat-chip-listbox
            [multiple]="false"
            #matChipListbox
            name="matChipListBox"
          >
            <mat-chip-option
              #machineRedOption
              [value]="ScreeningResult.MachineRed"
              (click)="addFilter(ScreeningResult.MachineRed)"
              [ngClass]="[
                machineRedOption.selected ? machineRedResult.color : ''
              ]"
            >
              <div class="d-flex align-items-center justify-content-around">
                <div>
                  {{ machineRedResult.statusLabel }}
                </div>
                <mat-icon
                  class="mx-1"
                  [svgIcon]="machineRedResult.icon"
                ></mat-icon>
              </div>
            </mat-chip-option>
            <mat-chip-option
              #redOption
              [value]="ScreeningResult.Red"
              (click)="addFilter(ScreeningResult.Red)"
              [ngClass]="[redOption.selected ? redResult.color : '']"
            >
              <div class="d-flex align-items-center justify-content-around">
                <div>Red</div>
                <mat-icon class="mx-1" [svgIcon]="redResult.icon"></mat-icon>
              </div>
            </mat-chip-option>
            <mat-chip-option
              #greenOption
              [value]="ScreeningResult.Green"
              (click)="addFilter(ScreeningResult.Green)"
              [ngClass]="[greenOption.selected ? greenResult.color : '']"
            >
              <div class="d-flex align-items-center justify-content-around">
                <div>Green</div>
                <mat-icon class="mx-1" [svgIcon]="greenResult.icon"></mat-icon>
              </div>
            </mat-chip-option>
          </mat-chip-listbox>
        </form>
      </ng-container>
    </div>
  </mat-card-header>
  <mat-card-content class="flex-fill overflow-auto">
    <ng-container *ngIf="loading; then spinner"></ng-container>
    <mat-list *ngIf="fvs && fvs.content && fvs.content.length > 0 && !loading">
      <ng-container *ngFor="let fv of fvs.content">
        <app-fv-list-item
          [showGoTo]="true"
          [showOwningOrg]="false"
          [showRemove]="isDeletable"
          [item]="fv"
          (removed)="onRemove($event)"
          [size]="'small'"
        >
        </app-fv-list-item>
        <mat-divider></mat-divider>
      </ng-container>
    </mat-list>
    <div
      *ngIf="fvs && fvs.content && fvs.content.length === 0 && !loading"
      class="mt-2"
      style="height: 97%"
    >
      <app-empty-state [message]="'No Foreign National Records'">
        <span matTooltip="Add Foreign Nationals">
          <button
            mat-flat-button
            color="accent"
            *ngIf="isEditable && !filterParams.result"
            #addFnTrigger="matMenuTrigger"
            [matMenuTriggerFor]="addFnMenu"
            id="add-foreign-national"
          >
            <span>Add Foreign Nationals</span>
          </button>
        </span>
      </app-empty-state>
    </div>
  </mat-card-content>
  <mat-card-footer *ngIf="fvs && fvs.totalElements && fvs.totalElements > 5"
    ><app-paginator
      [filterParams]="filterParams"
      (filterParamsChange)="onFilterParamChange($event)"
      [pageIndex]="fvs?.number"
      [pageSize]="fvs?.size"
      [totalElements]="fvs?.totalElements"
      [showFirstLastButtons]="false"
    ></app-paginator
  ></mat-card-footer>
</mat-card>
<ng-template #spinner>
  <mat-spinner></mat-spinner>
</ng-template>
