import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/shared/helpers';
import { UserPreferencesComponent } from './user-preferences.component';
import { Roles } from '@shared/models';

const routes: Routes = [
  {
    path: 'user-preferences',
    canActivate: [AuthGuard],
    component: UserPreferencesComponent,
    data: { roles: Roles },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserPreferencesRoutingModule {}
