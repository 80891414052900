import {
  Component,
  EventEmitter,
  Input,
  DoCheck,
  IterableDiffer,
  IterableDiffers,
  Output,
} from '@angular/core';
import { DiplomaticId, ForeignVisitor, Visa } from '@app/shared/models';
import { ConfirmDialogData } from '@app/shared/components';
import { MatDialog } from '@angular/material/dialog';
import {
  AlertService,
  CrudChangeEvent,
  CrudChangeType,
  FvService,
} from '@app/shared/services';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DiplomaticPassportDialogComponent } from './diplomatic-passport-dialog/diplomatic-passport-dialog.component';

export interface DialogData<T> {
  mode: 'create' | 'update' | 'delete';
  model: T;
}
@Component({
  selector: 'app-fvview-diplomatic-passport',
  templateUrl: './fvview-diplomatic-passport.component.html',
  styleUrls: ['./fvview-diplomatic-passport.component.scss'],
})
//implements DoCheck
export class FvviewDiplomaticPassportComponent implements DoCheck {
  @Input() showActions: boolean = true;
  @Input() parentId: string;
  @Input() set documentIds(data: DiplomaticId[]) {
    this._data = data;
  }
  get documentIds() {
    return this._data;
  }

  @Input() deletable?: boolean = false;
  @Input() editable?: boolean = true;
  @Output() diplomaticChange = new EventEmitter();
  refresh$: Observable<CrudChangeEvent<ForeignVisitor>> =
    this.fvService.changeEvent$.pipe(
      startWith({} as CrudChangeEvent<ForeignVisitor>)
    );
  private _data: DiplomaticId[];
  private _iterableDiffer: IterableDiffer<DiplomaticId>;

  documentIds$ = this.refresh$.pipe(
    map((refresh: CrudChangeEvent<ForeignVisitor>) => {
      if (refresh.type === CrudChangeType.delete)
        this.removeDiplomaticIdRecord(refresh.modelId as string);
      if (refresh.type === CrudChangeType.create)
        this.addDiplomaticIdRecord(refresh.model as DiplomaticId);
      if (refresh.type === CrudChangeType.update)
        this.updateDiplomaticIdRecord(refresh.model as DiplomaticId);
      this.diplomaticChange.emit(this.documentIds);
      this.sortDiplomaticIds();
      return this.documentIds;
    })
  );

  constructor(
    private fvService: FvService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private iterableDiffers: IterableDiffers
  ) {
    this._iterableDiffer = this.iterableDiffers.find([]).create();
  }

  ngDoCheck() {
    let changes = this._iterableDiffer.diff(this._data);
    if (changes) {
      this.documentIds = this._data;
    }
  }

  createDiplomaticId() {
    this.openDialog({
      foreignVisitor: { id: this.parentId },
    });
  }

  addDiplomaticIdRecord(documentIdRecord: DiplomaticId) {
    if (this.documentIds) {
      this.documentIds = [...this.documentIds, documentIdRecord];
    }
  }

  updateDiplomaticId(documentId: DiplomaticId) {
    this.openDialog({
      ...documentId,
      foreignVisitor: { id: this.parentId },
    });
  }

  updateDiplomaticIdRecord(documentIdRecord: DiplomaticId) {
    if (this.documentIds) {
      const index = this.documentIds.findIndex((documentId) => {
        return documentId.id === documentIdRecord.id;
      });
      this.documentIds[index] = documentIdRecord;
      this.documentIds = [...this.documentIds];
    }
  }

  removeDiplomaticId(documentId: DiplomaticId) {
    let confirmData: ConfirmDialogData = {
      title: `Remove Diplomatic Id #${documentId.documentId}`,
      message: `Are you sure? This action cannot be undone.`,
      performAction: () =>
        this.fvService.deleteFvDocId(this.parentId, documentId.documentId!),
    };
    this.alertService.confirmDelete(confirmData);
  }

  removeDiplomaticIdRecord(id: string) {
    if (this.documentIds) {
      this.documentIds = this.documentIds.filter((x) => x.id !== id);
    }
  }

  sortDiplomaticIds() {
    this.documentIds = this.documentIds.sort((a, b) =>
      `${b.createdDate}`.localeCompare(`${a.createdDate}`)
    );
  }

  openDialog(documentId: DiplomaticId) {
    this.dialog.open(DiplomaticPassportDialogComponent, {
      data: documentId,
      width: '1200px',
    });
  }
}
