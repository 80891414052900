<h1 mat-dialog-title>Diplomatic Id</h1>
<mat-dialog-content>
  <app-diplomatic-passport
    #diplomaticForm
    id="diplomaticForm"
    name="diplomaticId"
    [(ngModel)]="documentId"
    [showClearButton]="false"
  ></app-diplomatic-passport>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-end">
  <button
    mat-button
    color="primary"
    id="diplomatic-id-edit-cancel"
    mat-dialog-close
  >
    Cancel
  </button>
  <button
    [disabled]="diplomaticForm?.diplomaticForm?.invalid || busy"
    id="updateDiplomaticId"
    mat-raised-button
    color="primary"
    (click)="submitDiplomaticId()"
  >
    <span *ngIf="!busy">Submit</span>
    <mat-spinner
      class="mr-1 ml-1"
      *ngIf="busy"
      color="primary"
      diameter="35"
    ></mat-spinner>
  </button>
</mat-dialog-actions>
