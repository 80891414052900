<div
  class="row panel-banner p-2 mx-0 my-2 justify-content-between cursor-pointer"
  id="toggleOrganizationGraphs"
  (click)="display = !display"
>
  <div class="align-items-center d-flex">
    <mat-icon class="mr-2" svgIcon="chart-histogram"></mat-icon> Screening
    Results by Organization
  </div>
  <mat-icon svgIcon="chevron-up" *ngIf="display"></mat-icon>
  <mat-icon svgIcon="chevron-down" *ngIf="!display"></mat-icon>
</div>

<mat-card class="my-2" *ngIf="display">
  <div class="d-flex screening-results-picker">
    <mat-form-field appearance="outline">
      <mat-label>Select Dates</mat-label>
      <mat-date-range-input [rangePicker]="screeningDatePicker" [max]="today">
        <input
          id="orgDateStart"
          name="orgDateStart"
          matStartDate
          [(ngModel)]="startDate"
          placeholder="MM-DD-YYYY"
        />
        <input
          id="orgDateEnd"
          name="orgDateEnd"
          matEndDate
          [(ngModel)]="endDate"
          placeholder="MM-DD-YYYY"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="screeningDatePicker"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #screeningDatePicker></mat-date-range-picker>
    </mat-form-field>
    <button
      mat-mini-fab
      class="mx-1 mt-1"
      color="accent"
      style="border-radius: 5px"
      matTooltip="Search"
      (click)="getScreeningResultsMetrics()"
    >
      <mat-icon class="text-black">search</mat-icon>
    </button>
    <div>
      <mat-button-toggle-group
        name="datepickerType"
        aria-label="Datepicker Unit"
        [(ngModel)]="datepickerUnit"
        (ngModelChange)="dateRangeExceeded = false"
      >
        <mat-button-toggle value="DAY">Day</mat-button-toggle>
        <mat-button-toggle value="WEEK">Week</mat-button-toggle>
        <mat-button-toggle value="MONTH">Month</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="ml-2" style="margin-top: 0.6rem">
      <mat-icon
        color="primary"
        svgIcon="information"
        style="height: 30px; width: 30px"
        matTooltip="Click on Legend entry to toggle visibility. Click on bar to see details."
      ></mat-icon>
    </div>
    <div
      class="d-flex justify-content-end mr-2"
      style="flex: 1"
      *ngIf="orgChart.length > 0"
    >
      <button
        mat-icon-button
        (click)="showTotalCounts()"
        matTooltip="Show Total Counts"
      >
        <mat-icon svgIcon="chart-box"></mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="dateRangeExceeded" class="range-error">
    {{ datepickerUnit }} cannot exceed a range of
    {{ datepickerUnitRange[datepickerUnit] }} days between Start and End Dates
  </div>
</mat-card>
<div class="row mb-2" *ngIf="display">
  <div class="col-12">
    <mat-sidenav-container>
      <mat-sidenav #sideNav mode="side" position="end">
        <mat-toolbar class="border-bottom d-flex flex-row pl-2 pr-0 h-40">
          <h5 class="m-2" *ngIf="orgStatDetails">
            Details - {{ orgStatDetails.series }}
          </h5>
          <span class="col"></span>
          <button
            data-cy="close-details-sidebar-btn"
            id="close-details-button"
            mat-icon-button
            matTooltip="Close Details Drawer"
            (click)="sideNav.toggle(); sideNavOpen = false"
          >
            <mat-icon svgIcon="close"></mat-icon>
          </button>
        </mat-toolbar>
        <div class="details-content">
          <div
            class="row mx-0 pt-2"
            *ngIf="orgStatDetails && orgStatDetails.orgData"
          >
            <b class="px-2 pb-1 w-100">{{ orgStatDetails.name }}</b>
            <div class="col">
              <dl>
                <dt>Total FNs</dt>
                <dd>{{ orgStatDetails.orgData.totalCount || 0 }}</dd>
                <dt>In Process</dt>
                <dd>
                  {{ orgStatDetails.orgData.inProcessStatusCount || 0 }}
                </dd>
                <dt>New</dt>
                <dd>{{ orgStatDetails.orgData.newStatusCount || 0 }}</dd>
                <dt>Completed</dt>
                <dd>
                  {{ orgStatDetails.orgData.completedStatusCount || 0 }}
                </dd>
                <dt>Errors</dt>
                <dd>{{ orgStatDetails.orgData.errorStatusCount || 0 }}</dd>
                <dt>USP</dt>
                <dd>
                  {{ orgStatDetails.orgData.uspResultCount || 0 }}
                </dd>
              </dl>
            </div>
            <mat-divider vertical></mat-divider>
            <div class="col">
              <dl>
                <dt>Derogatory</dt>
                <dd>{{ orgStatDetails.orgData.redResultCount || 0 }}</dd>
                <dt>Inconclusive</dt>
                <dd>
                  {{ orgStatDetails.orgData.yellowResultCount || 0 }}
                </dd>
                <dt>Total Greens</dt>
                <dd>{{ orgStatDetails.orgData.greenResultCount || 0 }}</dd>
                <dt>Machine Green</dt>
                <dd>
                  {{ orgStatDetails.orgData.machineGreenResultCount || 0 }}
                </dd>
                <dt>Reviewed Green</dt>
                <dd>
                  {{ orgStatDetails.orgData.reviewedGreenResultCount || 0 }}
                </dd>
                <dt>Machine Red</dt>
                <dd>
                  {{ orgStatDetails.orgData.machineRedResultCount || 0 }}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </mat-sidenav>
      <mat-sidenav-content>
        <mat-card [class]="sideNavOpen ? 'mr-2' : ''">
          <mat-card-content>
            <div #containerRef *ngIf="!loading; else dataLoading">
              <ngx-charts-bar-vertical-2d
                [view]="[containerRef.offsetWidth, containerRef.offsetHeight]"
                [results]="orgChart"
                [gradient]="gradient"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [legendTitle]="orgLegendTitle"
                [animations]="false"
                (select)="onSelectOrgs($event, sideNav)"
              >
              </ngx-charts-bar-vertical-2d>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>

<ng-template #dataLoading>
  <div class="my-4" style="margin-left: 45%">
    <mat-spinner color="primary"></mat-spinner></div
></ng-template>
