import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgIdleModule } from '@ng-idle/core';

import { SharedModule } from './shared/shared.module';

import { ErrorInterceptor, JwtInterceptor } from './shared/helpers';

//Routing
import { AppRoutingModule } from './app-routing.module';

//Components
//Root - Entry Point
import { AppComponent } from './app.component';

//Home
import { HomePageComponent } from './home';

//Modules
import { UserProfileModule } from './user-profile/user-profile.module';
import { AdminModule } from './admin/admin.module';
import { ForeignVisitorsModule } from './foreign-visitors/foreign-visitors.module';
import { ForeignAccessRequestModule } from './foreign-access-requests/foreign-access-request.module';
import { LocationsModule } from './locations/locations.module';
import { OrganizationsModule } from './organizations/organizations.module';
import { ScreeningModule } from './screening/screening.module';
import { HomePageModule } from './home/homepage.module';
import { MatIconRegistry } from '@angular/material/icon';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthModule } from '@app/auth/auth.module';
import { ApplicationToolbarComponent } from './application-toolbar/application-toolbar.component';
import {
  AsyncDetailsDialogComponent,
  AsyncDetailsDialogDirective,
} from './application-toolbar/async-details-dialog/async-details-dialog.component';
import { SandboxModule } from './shared/sandbox/sandbox.module';
import { SystemAlertsModule } from './system-alerts/system-alerts.module';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { NgxWebstorageModule } from 'ngx-webstorage';
import {
  LoggerModule,
  NgxLoggerLevel,
  TOKEN_LOGGER_CONFIG,
  TOKEN_LOGGER_SERVER_SERVICE,
} from 'ngx-logger';
import { GlobalErrorHandler } from '@shared/logging/global-error-handler';
import { LogSenderService } from '@shared/logging/log-sender.service';
import {
  auditAppInitializerFactory,
  AuditService,
} from '@shared/logging/audit.service';
import { AppConfigService } from '@shared/services/app-config.services';
import {
  classificationAppInitializerFactory,
  ClassificationService,
} from '@shared/cmt/classification.service';
import { SuccessMessageInterceptor } from './shared/helpers/success-message.interceptor';
import { MetricsDashboardModule } from './metrics-dashboard/metrics-dashboard.module';
import { TitleStrategy } from '@angular/router';
import { AppTitleStrategy } from '@shared/helpers/app-title.strategy';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import { MonitoringModule } from '@app/monitoring/monitoring.module';
import { UserPreferencesModule } from './user-preferences/user-preferences.component.module';
import { GroupModule } from './groups/group.module';

const MAT_FORM_FIELD_APP_OPTIONS: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
    FormsModule,
    AuthModule,
    ForeignVisitorsModule,
    ForeignAccessRequestModule,
    LocationsModule,
    OrganizationsModule,
    MetricsDashboardModule,
    MonitoringModule,
    UserProfileModule,
    GroupModule,
    HomePageModule,
    ScreeningModule,
    AdminModule,
    SandboxModule,
    SystemAlertsModule,
    UserPreferencesModule,
    AppRoutingModule,
    NgIdleModule.forRoot(),
    NgxWebstorageModule.forRoot(),
    LoggerModule.forRoot(null, {
      configProvider: {
        provide: TOKEN_LOGGER_CONFIG,
        useFactory: (config: AppConfigService) => ({
          level: config.get('consoleLogLevel', NgxLoggerLevel.ERROR),
          serverLoggingUrl: 'logs',
          serverLogLevel: config.get('serverLogLevel', NgxLoggerLevel.INFO),
          enableSourceMaps: true,
        }),
        deps: [AppConfigService],
      },
      serverProvider: {
        provide: TOKEN_LOGGER_SERVER_SERVICE,
        useClass: LogSenderService,
      },
    }),
  ],
  declarations: [
    AppComponent,
    HomePageComponent,
    ApplicationToolbarComponent,
    AsyncDetailsDialogComponent,
    AsyncDetailsDialogDirective,
  ],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { disableClose: true, autoFocus: true, delayFocusTrap: true },
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: MAT_FORM_FIELD_APP_OPTIONS,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SuccessMessageInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: auditAppInitializerFactory,
      deps: [AuditService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: classificationAppInitializerFactory,
      deps: [ClassificationService],
      multi: true,
    },
    { provide: TitleStrategy, useClass: AppTitleStrategy },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  countries = 'us';
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    // registers the citadel logo svg files to use with material icons
    // <mat-icon [svgIcon]="logo | vertical-logo | horizontal-logo"
    const LOGO_ICONS = ['vertical-logo', 'horizontal-logo', 'logo'];
    LOGO_ICONS.forEach((logo) => {
      matIconRegistry.addSvgIcon(
        logo.toLocaleLowerCase(),
        domSanitizer.bypassSecurityTrustResourceUrl(
          `./assets/icons/CITADEL/CITADEL.${logo}.svg`
        )
      );
    });

    matIconRegistry.registerFontClassAlias('flag', 'fi');
    matIconRegistry.addSvgIconSet(
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg')
    );
    i18nIsoCountries.registerLocale(
      require('i18n-iso-countries/langs/en.json')
    );

    Object.keys(i18nIsoCountries.getAlpha2Codes())
      .concat(['xx'])
      .forEach((code) => {
        matIconRegistry.addSvgIcon(
          `flag-${code.toLocaleUpperCase()}`,
          domSanitizer.bypassSecurityTrustResourceUrl(
            `./assets/flags/4x3/${code.toLocaleLowerCase()}.svg`
          )
        );
        matIconRegistry.addSvgIcon(
          `flag-${code.toLocaleLowerCase()}`,
          domSanitizer.bypassSecurityTrustResourceUrl(
            `./assets/flags/4x3/${code.toLocaleLowerCase()}.svg`
          )
        );
        matIconRegistry.addSvgIcon(
          `flag-sq-${code.toLocaleUpperCase()}`,
          domSanitizer.bypassSecurityTrustResourceUrl(
            `./assets/flags/1x1/${code.toLocaleLowerCase()}.svg`
          )
        );
        matIconRegistry.addSvgIcon(
          `flag-sq-${code.toLocaleLowerCase()}`,
          domSanitizer.bypassSecurityTrustResourceUrl(
            `./assets/flags/1x1/${code.toLocaleLowerCase()}.svg`
          )
        );
      });
  }
}
