<ng-container *ngIf="documentIds.length; else emptyCollectionButtonStyle">
  <mat-expansion-panel
    class="flex-fill mt-3 panel-200-mh"
    #diplomaticIdPanel="matExpansionPanel"
    id="fv-view-diplomatic-ids-panel"
    *ngIf="documentIds.length"
  >
    <mat-expansion-panel-header>
      <mat-panel-title class="d-flex justify-content-between py-2">
        <span>Diplomatic ID</span>
        <mat-icon
          svgIcon="card-text-outline"
          [matBadge]="documentIds.length"
          matBadgeSize="small"
          matBadgeColor="accent"
        ></mat-icon>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-divider class="panel-header-divider"></mat-divider>
    <div class="diplomatic-ids-body">
      <ng-container *ngFor="let item of documentIds; index as i">
        <div class="content row">
          <div class="col">
            <div class="row">
              <div class="col-6">
                <dl>
                  <dt>Diplomatic ID Number</dt>
                  <dd>{{ item.documentId | default }}</dd>
                </dl>
              </div>
              <div class="col-6">
                <dl style="line-break: anywhere">
                  <dt>Issue Date</dt>
                  <dd>
                    {{ item.issueDate | militaryDate | default }}
                  </dd>
                  <dt>Expiration Date</dt>
                  <dd>
                    {{ item.expirationDate | militaryDate | default }}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <mat-divider
            [vertical]="true"
            class="panel-vertical-divider"
          ></mat-divider>
          <div
            class="col-2 d-flex justify-content-center align-items-center"
            style="flex-direction: column"
          >
            <button
              *ngIf="editable"
              mat-icon-button
              (click)="updateDiplomaticId(item)"
              matTooltip="Edit Diplomatic Id"
            >
              <mat-icon svgIcon="square-edit-outline"></mat-icon>
            </button>

            <div class="flex-fill"></div>
          </div>
        </div>
        <mat-divider
          *ngIf="i + 1 < documentIds.length"
          class="panel-header-divider"
        ></mat-divider>
      </ng-container>
    </div>
  </mat-expansion-panel>
</ng-container>
<ng-template #emptyCollectionButtonStyle>
  <button
    class="mt-3 w-100"
    style="height: 48px"
    mat-raised-button
    matTooltip="Add New Diplomatic Ids"
    color="primary"
    (click)="createDiplomaticId()"
    [disabled]="!editable"
  >
    <div class="d-flex align-items-center">
      <span>Add Diplomatic Id</span>
      <mat-icon class="ml-2" svgIcon="card-text-outline"></mat-icon>
    </div>
  </button>
</ng-template>
