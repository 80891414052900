import { Component, Input } from '@angular/core';
import { FAR } from '@shared/models';

@Component({
  selector: 'app-far-metadata',
  templateUrl: './far-metadata.component.html',
  styleUrls: ['./far-metadata.component.scss'],
})
export class FarMetadataComponent {
  @Input() far: FAR;
  @Input() tableDisplay: boolean = false;

  get createdByFullName() {
    const givenName = this.far.auditMetadata?.createdByGivenName;
    const surName = this.far.auditMetadata?.createdBySurname;
    return `${givenName} ${surName}`;
  }

  get owningOrgShortName() {
    return this.far.auditMetadata?.owningOrganizationShortName!;
  }

  get owningOrgName() {
    return this.far.auditMetadata?.owningOrganizationName!;
  }

  get networkDomain() {
    return this.far.networkDomain!;
  }

  get comments() {
    return this.far.commentCount!;
  }

  get attachmentCount() {
    return this.far.attachmentCount!;
  }

  get deletionDate() {
    return this.far.deletionDate!;
  }
}
