import {
  Component,
  EventEmitter,
  Input,
  DoCheck,
  IterableDiffer,
  IterableDiffers,
  Output,
} from '@angular/core';
import { Visa } from '@app/shared/models';
import { ConfirmDialogData } from '@app/shared/components';
import { MatDialog } from '@angular/material/dialog';
import {
  AlertService,
  CrudChangeEvent,
  CrudChangeType,
} from '@app/shared/services';
import { VisaService } from '@app/shared/services/visa.service';
import { VisaDialogComponent } from './visa-dialog/visa-dialog.component';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-fvview-visa',
  templateUrl: './fvview-visa.component.html',
  styleUrls: ['./fvview-visa.component.scss'],
})
export class FvviewVisaComponent implements DoCheck {
  @Input() showActions: boolean = true;
  @Input() parentId: string;
  @Input() set visas(data: Visa[]) {
    this._data = data;
  }
  get visas() {
    return this._data;
  }

  @Input() deletable?: boolean = false;
  @Input() editable?: boolean = true;
  @Output() visaChange = new EventEmitter();
  refresh$: Observable<CrudChangeEvent<Visa>> =
    this.visaService.changeEvent$.pipe(startWith({} as CrudChangeEvent<Visa>));
  private _data: Visa[];
  private _iterableDiffer: IterableDiffer<Visa>;

  visas$ = this.refresh$.pipe(
    map((refresh: CrudChangeEvent<Visa>) => {
      if (refresh.type === CrudChangeType.delete)
        this.removeVisaRecord(refresh.modelId as string);
      if (refresh.type === CrudChangeType.create)
        this.addVisaRecord(refresh.model as Visa);
      if (refresh.type === CrudChangeType.update)
        this.updateVisaRecord(refresh.model as Visa);
      this.visaChange.emit(this.visas);
      this.sortVisas();
      return this.visas;
    })
  );

  constructor(
    private visaService: VisaService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private iterableDiffers: IterableDiffers
  ) {
    this._iterableDiffer = this.iterableDiffers.find([]).create();
  }

  ngDoCheck() {
    let changes = this._iterableDiffer.diff(this._data);
    if (changes) {
      this.visas = this._data;
    }
  }

  createVisa() {
    this.openDialog({ foreignVisitor: { id: this.parentId } });
  }
  addVisaRecord(visaRecord: Visa) {
    if (this.visas) {
      this.visas = [...this.visas, visaRecord];
    }
  }

  updateVisa(visa: Visa) {
    this.openDialog({ ...visa, foreignVisitor: { id: this.parentId } });
  }
  updateVisaRecord(visaRecord: Visa) {
    if (this.visas) {
      const index = this.visas.findIndex((visa) => {
        return visa.id === visaRecord.id;
      });
      this.visas[index] = visaRecord;
      this.visas = [...this.visas];
    }
  }

  removeVisa(visa: Visa) {
    let confirmData: ConfirmDialogData = {
      title: `Remove Visa #${visa.number}`,
      message: `Are you sure? This action cannot be undone.`,
      performAction: () => this.visaService.delete(visa.id),
    };
    this.alertService.confirmDelete(confirmData);
  }
  removeVisaRecord(id: string) {
    if (this.visas) {
      this.visas = this.visas.filter((x) => x.id !== id);
    }
  }

  sortVisas() {
    this.visas = this.visas.sort((a, b) =>
      `${b.createdDate}`.localeCompare(`${a.createdDate}`)
    );
  }

  openDialog(visa: Visa) {
    this.dialog.open(VisaDialogComponent, {
      data: visa,
      width: '1000px',
    });
  }
}
