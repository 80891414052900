import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FilterParams,
  Role,
  ScreeningStatus,
  ScreeningStatusLabels,
} from '@app/shared/models';
import { AlertService, ScreeningService } from '@app/shared/services';
import { ActionButton } from '@app/shared/models/actionbutton.model';
import { ScreeningDrawerObservable } from '../../screening-drawer-observable.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SortSelect } from '@app/shared/components';
import { HttpResponse } from '@angular/common/http';
import { ScreeningFieldLabels } from '../screening-table/screening-table.component';
import { ScreeningField } from '../screening-table/screening-table.component';
import { AppConfigService } from '@app/shared/services';
import { ScreeningAdvancedSearch } from '@app/screening/collection/screening-advanced-search';

@Component({
  selector: 'app-screening-toolbar',
  templateUrl: './screening-toolbar.component.html',
  styleUrls: ['./screening-toolbar.component.scss'],
})
export class ScreeningToolbarComponent implements OnDestroy {
  @Input() loading: boolean = false;
  @Input() filterPlaceholder: string = 'Name Filter';
  @Input() sortFields: SortSelect[];
  @Output() filterParamsChange: EventEmitter<FilterParams> = new EventEmitter();
  @Input() filterParams: FilterParams = new FilterParams({});
  screenable: boolean = true;
  private ngUnsubscribe = new Subject<void>();
  ScreeningFieldLabel = ScreeningFieldLabels;
  automatedScreeningEnabled = this.config.get('automatedScreeningEnabled');

  fvColumns: string[] = [
    ScreeningField.SURNAME,
    ScreeningField.REQUESTED_DATE,
    ScreeningField.MODIFIED_DATE,
    ScreeningField.COMPLETED_DATE,
    ScreeningField.STATUS,
    ScreeningField.RESULT,
    ScreeningField.NOTES,
    ScreeningField.NETWORK_DOMAIN,
    'totalScreenings',
    'actions',
  ];

  statusFilter: string = '';
  actionButton: ActionButton = {
    label: 'Import',
    bulkUpload: 'Screening',
    menuIcon: 'input',
    roles: [Role.sv_vetter],
    color: 'primary',
  };

  fvAdvFiltersList: {
    key: string;
    label: string;
    valLabel?: (val: string) => string;
  }[] = [
    { key: 'givenName', label: 'Given Name' },
    { key: 'surname', label: 'Surname' },
    { key: 'emailAddress', label: 'Email Address' },
    { key: 'screeningCompletedDate', label: 'Screening Completed Date' },
    { key: 'passportNumber', label: 'Passport Number' },
    { key: 'passportIssuingCountryCode', label: 'Passport Issuing Country' },
    { key: 'citizenshipCountry', label: 'Citizenship Country' },
    { key: 'residenceCountry', label: 'Residence Country' },
    { key: 'birthCountry', label: 'Birth Country' },
    { key: 'dateOfBirth', label: 'Date of Birth' },
    {
      key: 'status',
      label: 'Screening Status',
      valLabel: (val) => ScreeningStatusLabels[val as ScreeningStatus],
    },
    { key: 'result', label: 'Screening Result' },
    { key: 'offenseCode', label: 'Screening Offense Code' },
  ];

  constructor(
    private alert: AlertService,
    private screeningService: ScreeningService,
    private _ScreeningDrawerObservable: ScreeningDrawerObservable,
    private config: AppConfigService
  ) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onFilterParamChange(filterParams: FilterParams) {
    this.filterParamsChange.emit(filterParams);
  }

  openHistoryDrawer() {
    this._ScreeningDrawerObservable.send(true);
  }

  isFilterActive() {
    for (let filter of this.fvAdvFiltersList) {
      if (this.filterParams[filter.key]) return true;
    }
    if (this.filterParams['vip']) return true;
    return false;
  }

  clearAllFilters() {
    this.fvAdvFiltersList.forEach((filter) => {
      this.filterParams[filter.key] = null;
    });
    this.filterParams['vip'] = null;
    this.onFilterParamChange(this.filterParams);
  }

  export(isVip: boolean) {
    this.alert.showLoadingOverlay();
    this.screeningService
      .exportScreenings(isVip)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.handleExport(res, isVip);
      });
  }
  exportVip() {
    this.export(true);
  }

  exportAll() {
    this.export(false);
  }

  handleExport(response: HttpResponse<Blob>, isVip: boolean) {
    let message: string;
    this.alert.hideLoadingOverlay();

    if (response['status'] === 204)
      this.alert.infoAlert('No Foreign Nationals Ready for Export');
    else {
      this.processFileForBrowser(response['body'] as Blob, isVip);
    }
  }

  processFileForBrowser(responseData: Blob, isVip: boolean) {
    let fileType: string;
    fileType = responseData.type == 'application/zip' ? '.zip' : '.xlsx';
    const data = window.URL.createObjectURL(responseData);

    let link = document.createElement('a');
    let fileTitle = `${isVip ? 'EXPEDITED-' : ''}SCREENING-'`;

    link.href = data;
    link.download =
      fileTitle +
      (new Date().getUTCMonth() + 1) +
      new Date().getUTCDate() +
      '_' +
      new Date().getUTCHours() +
      new Date().getUTCMinutes() +
      fileType;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);

    this._ScreeningDrawerObservable.send('refresh');
    this.filterParamsChange.emit(this.filterParams);
  }

  protected readonly ScreeningAdvancedSearch = ScreeningAdvancedSearch;
}
