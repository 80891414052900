import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
  AbstractControl,
  ValidationErrors,
  NgForm,
} from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { BaseControlComponent } from '@app/shared/controls';
import { hasValues } from '@app/shared/helpers';
import { DiplomaticId } from '@app/shared/models/diplomatic-passport.model';

@Component({
  selector: 'app-diplomatic-passport',
  templateUrl: './diplomatic-passport.component.html',
  styleUrls: ['./diplomatic-passport.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DiplomaticPassportComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DiplomaticPassportComponent),
      multi: true,
    },
  ],
})
export class DiplomaticPassportComponent
  extends BaseControlComponent<DiplomaticId>
  implements Validator, OnInit
{
  @Input() editable: boolean = true;
  @Input() deletable?: boolean = false;
  isDiplomaticPassport: boolean = false;
  @Input() showClearButton: boolean = true;
  @Input() minIssueDate?: string;
  @Output() validDiplomaticPassport = new EventEmitter<boolean>();
  @ViewChild('diplomaticForm')
  diplomaticForm: NgForm;
  appearance: MatFormFieldAppearance = 'outline';
  today = new Date();
  value: DiplomaticId = {};
  @Input() parentId: string;

  onValidationChange: any = () => {};

  constructor() {
    super();
  }

  addDiplomaticPassport() {
    this.emitChangeEvent();
  }

  checkDiplomaticPassport() {
    // Setting isPassport for diplomatic passport componenet
    this.isDiplomaticPassport = this.hasDiplomaticPassportData() ? false : true;
    this.emitChangeEvent();
  }

  getEmitValue(): DiplomaticId | null {
    return this.hasDiplomaticPassportData() ? this.value : null;
  }

  ngAfterViewInit() {
    this.diplomaticForm.valueChanges?.subscribe(() => {
      this.onValidationChange();
      this.emitChangeEvent();
    });
  }

  hasDiplomaticPassportData() {
    return hasValues(this.value);
  }

  ngOnInit() {
    this.value = {};
  }

  writeValue(_value: DiplomaticId): void {
    super.writeValue(_value);
    this.value = this.value || {};
  }

  validate(_control: AbstractControl): ValidationErrors | null {
    return this.diplomaticForm?.valid ? null : { documentIds: true };
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }
}
