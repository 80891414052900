<div class="grid-collection dialog-collection">
  <h3 mat-dialog-title>Bulk Upload Results - {{ data["name"] }}</h3>
  <ng-container *ngIf="upload$ | async as upload">
    <div class="grid-main">
      <ng-container
        [ngTemplateOutlet]="
          hasData(data.duplicate) && hasData(data['screening ineligible'])
            ? tabGroups
            : oneTable
        "
        [ngTemplateOutletContext]="{ upload: upload }"
      >
      </ng-container>
    </div>
    <mat-dialog-actions class="justify-content-end">
      <button
        mat-button
        color="primary"
        id="closeResultDialog"
        mat-dialog-close
      >
        Close
      </button>
      <button
        mat-flat-button
        color="primary"
        id="saveResultDialog"
        (click)="saveAll(upload.foreignVisitors)"
      >
        Save and Close
      </button>
    </mat-dialog-actions>
  </ng-container>
  <ng-template #oneTable let-upload="upload">
    <ng-container
      *ngIf="data.duplicate && data.duplicate.messages"
      [ngTemplateOutlet]="duplicateTab"
    >
    </ng-container>
    <ng-container
      *ngIf="
        data['screening ineligible'] && data['screening ineligible'].messages
      "
    >
      <ng-container
        *ngTemplateOutlet="
          ineligibleTab;
          context: { upload: createPassports(upload.foreignVisitors) }
        "
      >
      </ng-container>
    </ng-container>
  </ng-template>
  <ng-template #ineligibleTab let-upload="upload">
    <mat-table *ngIf="upload" [dataSource]="upload" multiTemplateDataRows>
      <ng-container [matColumnDef]="'surname'">
        <mat-header-cell class="justify-content-start" *matHeaderCellDef>
          Foreign National
        </mat-header-cell>
        <mat-cell class="justify-content-start" *matCellDef="let fv">
          <div class="d-flex align-self-center">
            {{ fv | fullname: "surnameFirst" }}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container [matColumnDef]="'dob'">
        <mat-header-cell class="justify-content-center" *matHeaderCellDef>
          DOB
        </mat-header-cell>
        <mat-cell
          class="justify-content-center"
          *matCellDef="let fv; index as i"
        >
          <div class="d-flex align-self-center">
            <mat-form-field appearance="outline">
              <mat-label>Date of Birth</mat-label>
              <input
                [(ngModel)]="fv.dateOfBirth"
                name="dateOfBirth-{{ i }}"
                matInput
                [matDatepicker]="dobPicker"
                [max]="today"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="dobPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #dobPicker></mat-datepicker>
              <mat-error>Invalid Date</mat-error>
            </mat-form-field>
          </div>
        </mat-cell>
      </ng-container>
      <ng-container [matColumnDef]="'passportNumber'">
        <mat-header-cell class="justify-content-center" *matHeaderCellDef>
          Passport Number
        </mat-header-cell>
        <mat-cell
          class="justify-content-center pl-0"
          *matCellDef="let fv; index as i"
        >
          <div class="d-flex align-self-center">
            <mat-form-field appearance="outline" *ngIf="fv.passports">
              <mat-label>Passport Number</mat-label>
              <input
                #passportNumber="ngModel"
                matInput
                [(ngModel)]="fv.passports[0].number"
                [required]="
                  hasData(fv.passports[0].issuingCountryCode) ||
                  hasData(fv.passports[0].number)
                "
                name="passportNumber-{{ i }}"
              />
              <mat-error>Passport Number is required.</mat-error>
            </mat-form-field>
          </div>
        </mat-cell>
      </ng-container>
      <ng-container [matColumnDef]="'passportCountry'">
        <mat-header-cell class="justify-content-center" *matHeaderCellDef>
          Passport Country
        </mat-header-cell>
        <mat-cell
          class="justify-content-center pl-0"
          *matCellDef="let fv; index as i"
        >
          <div class="d-flex align-self-center">
            <app-country-select
              *ngIf="fv.passports"
              id="passportIssuingCountry"
              #passportIssuingCountryControl="ngModel"
              [(ngModel)]="fv.passports[0].issuingCountryCode"
              name="issuingCountryCode-{{ i }}"
              label="Passport Issuing Country Code"
              [excludeUS]="true"
              [required]="
                hasData(fv.passports[0].issuingCountryCode) ||
                hasData(fv.passports[0].number)
              "
              [includeOrgs]="true"
            ></app-country-select>
          </div>
        </mat-cell>
      </ng-container>
      <ng-container [matColumnDef]="'actions'">
        <mat-header-cell class="justify-content-center" *matHeaderCellDef>
          Actions
        </mat-header-cell>
        <mat-cell class="justify-content-center" *matCellDef="let fv">
          <div class="d-flex align-items-center">
            <button
              mat-icon-button
              (click)="quickSaveFV(fv)"
              matTooltip="Save Changes"
            >
              <mat-icon [svgIcon]="'account-convert'"></mat-icon>
            </button>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="resultColumn; sticky: true"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: resultColumn"></mat-row>
    </mat-table>
  </ng-template>
  <ng-template #duplicateTab>
    <ng-container *ngIf="data.duplicate">
      <ng-container *ngIf="data.duplicate.messages as messages">
        <mat-table [dataSource]="messages | keyvalue">
          <ng-container [matColumnDef]="'rowNumber'">
            <mat-header-cell class="justify-content-center" *matHeaderCellDef>
              Row
            </mat-header-cell>
            <mat-cell class="justify-content-center" *matCellDef="let message">
              <div class="d-flex align-items-center">
                {{ message.key | default }}
              </div>
            </mat-cell>
          </ng-container>
          <ng-container [matColumnDef]="'message'">
            <mat-header-cell class="justify-content-center" *matHeaderCellDef>
              Message
            </mat-header-cell>
            <mat-cell class="justify-content-center" *matCellDef="let message">
              <div class="d-flex align-items-center">
                {{ message.value | default }}
              </div>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="duplicateCols; sticky: true">
          </mat-header-row>
          <mat-row *matRowDef="let row; columns: duplicateCols"></mat-row>
        </mat-table>
      </ng-container>
    </ng-container>
  </ng-template>
  <ng-template #tabGroups let-upload="upload">
    <mat-tab-group>
      <mat-tab
        label="Ineligible FNs ({{
          (data['screening ineligible'].messages | keys).length
        }})"
      >
        <mat-dialog-content>
          <ng-container
            *ngTemplateOutlet="
              ineligibleTab;
              context: { upload: createPassports(upload.foreignVisitors) }
            "
          ></ng-container>
        </mat-dialog-content>
      </mat-tab>
      <mat-tab
        label="Duplicate FNs ({{ (data.duplicate.messages | keys).length }})"
      >
        <mat-dialog-content>
          <ng-container [ngTemplateOutlet]="duplicateTab"></ng-container>
        </mat-dialog-content>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</div>
