import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Crud, CrudService } from '@shared/services/crud.service';
import {
  DisplayUserPreference,
  LoadedDisplay,
  Role,
  SavedUserPreference,
  UserPreference,
  UserPreferenceBlob,
} from '../models';
import { BehaviorSubject, Observable, of, Subject, take, tap } from 'rxjs';
import { ColumnToggleEvent } from '../components/column-chooser/column-chooser.component';
import { ColumnChooserEventObservable } from '../components/column-chooser/column-chooser-observable.service';
@Injectable({
  providedIn: 'root',
})
@Crud({
  apiUrl: `${environment.apiUrl}/userPreferences`,
  entity: 'UserPreference',
})
export class UserPreferenceService extends CrudService<UserPreference> {
  savedUserPreference: SavedUserPreference;
  userPreferenceBlob: UserPreference;
  columnEvent: ColumnToggleEvent | undefined;
  updateCheckboxes = new Subject<LoadedDisplay>();
  updated = new BehaviorSubject(false);
  userPreferenceDisplaySubject$ = new BehaviorSubject<SavedUserPreference>({
    hasPreference: false,
  });

  userPreferenceSubject$ = new BehaviorSubject<{
    value: boolean;
    view: string;
  }>({ value: false, view: 'card' });
  updateDisplay = new Subject<DisplayUserPreference>();
  //private _ColumnChooserEventObservable: any;
  constructor(http: HttpClient) {
    super(http);
  }

  getCurrentUserId(id: string): Observable<UserPreference | null> {
    if (!id?.length) return of(null);
    return this.http.get<UserPreference>(
      `${this.apiUrl}/findByCurrentUserId/` + id
    );
  }

  updatePreference(id: string, up: UserPreference): Observable<UserPreference> {
    return this.http.put<UserPreference>(`${this.apiUrl}/${id}`, up).pipe(
      tap((m) =>
        this.changeEvent$.emit({
          type: 'update',
          model: m,
          modelId: m.id,
        })
      )
    );
  }

  getAllSavedData(): SavedUserPreference {
    return this.savedUserPreference;
  }

  loadSavedView(): UserPreference {
    return this.userPreferenceBlob;
  }

  setSavedView(userPreference: UserPreference): void {
    this.userPreferenceBlob = userPreference;
  }

  setAllSavedData(saved: SavedUserPreference): void {
    this.savedUserPreference = saved;
  }

  sendUpdatedCheckboxes(data: LoadedDisplay) {
    this.updateCheckboxes.next(data);
  }

  sendDisplayUpdates(displayUser: DisplayUserPreference) {
    this.updateDisplay.next(displayUser);
  }

  isUpdated(data: boolean) {
    this.updated.next(data);
  }

  hasUserPreference(id: string, label: string) {
    this.getCurrentUserId(id!)
      .pipe(take(1))
      .subscribe((x) => {
        if (x !== undefined && x !== null) {
          let jsonString: UserPreferenceBlob = JSON.parse(x.userPreferenceBlob);
          if (jsonString !== null && jsonString !== undefined) {
            if (label === 'Foreign Nationals (FN)') {
              this.userPreferenceSubject$.next({
                value: true,
                view: jsonString.tabs[0].tabViewType!,
              });
            } else if (label === 'Foreign Access Request (FAR)') {
              this.userPreferenceSubject$.next({
                value: true,
                view: jsonString.tabs[1].tabViewType!,
              });
            } else {
              this.userPreferenceSubject$.next({
                value: true,
                view: 'card',
              });
            }
          }
        }
      });
  }

  loadDisplayColumns(
    columns: any,
    selectedViewColumns: string[],
    tableColumns: any,
    tableName: string,
    roles: string[],
    network: string
  ): void {
    let viewTableColumns: { [key: string]: boolean } = {};
    let currentKey: string;

    if (selectedViewColumns.length === 0) {
      Object.keys(columns).forEach((key) => {
        viewTableColumns[`${key}`] = false;
      });
    }

    Object.entries(tableColumns).forEach((key, value) => {
      currentKey = columns[`${key[1]}`];

      if (`${key[1]}` === 'actions') {
        if (
          tableName === 'fvTable' ||
          tableName === 'farTable' ||
          (tableName === 'organizationTable' &&
            roles.includes(Role.sv_admin)) ||
          (tableName === 'userTable' &&
            roles.includes(Role.sv_admin) &&
            network === 'U') ||
          tableName === 'locationTable' ||
          tableName === '' ||
          tableName === 'groupTable'
        ) {
          currentKey = 'Actions';
        }
      }
      if (`${key[1]}` === 'additionalData') {
        currentKey = 'Additional Data';
      }

      if (selectedViewColumns.includes(`${currentKey}`)) {
        viewTableColumns[`${key[1]}`] = true;
      } else {
        viewTableColumns[`${key[1]}`] = false;
      }
    });

    let trueCount = Object.values(viewTableColumns).filter(
      (x) => x === true
    ).length;
    let falseCount = Object.values(viewTableColumns).filter(
      (x) => x === false
    ).length;
    let totalColumns = Object.values(viewTableColumns).length;
    let columnEventType: string = '';
    if (trueCount === totalColumns) {
      columnEventType = 'showAll';
    } else if (trueCount < totalColumns) {
      columnEventType = 'showAllPartial';
    }
    if (falseCount === totalColumns) {
      columnEventType = 'hideAll';
    } else if (falseCount < totalColumns) {
      columnEventType = 'hideAllPartial';
    }
    this.columnEvent = {
      eventType: columnEventType,
      columnNameArray: Object.keys(viewTableColumns),
      columns: tableColumns,
      tableName: tableName,
    };

    this.savedUserPreference = {
      hasPreference: true,
      selectedColumns: viewTableColumns,
      columnNames: tableColumns,
      tableName: tableName,
      event: this.columnEvent,
    };
    //this._ColumnChooserEventObservable.sendEvent(this.columnEvent);
    this.userPreferenceDisplaySubject$.next(this.savedUserPreference);
    this.setAllSavedData(this.savedUserPreference);
  }
}
