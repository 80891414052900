<div class="fn-card__profile p-2 component" *ngIf="fn">
  <mat-card-title
    class="d-flex align-items-center gap-1 text-primary"
    style="max-height: 32px"
  >
    <span *ngIf="groupSelectionMode">
      <mat-checkbox
        [(ngModel)]="fn.selected"
        (click)="$event.stopPropagation()"
      ></mat-checkbox>
    </span>
    <span
      *ngIf="(fn | fullname).length <= 28"
      [style]="groupSelectionMode ? 'margin-left: -5px' : ''"
    >
      {{ fn | fullname: "surnameFirst" | titlecase }}
    </span>
    <span
      matTooltip="{{ fn | fullname }}"
      *ngIf="(fn | fullname).length > 28"
      class="d-flex"
      [style]="groupSelectionMode ? 'margin-left: -5px' : ''"
    >
      {{ fn | fullname: "surnameFirst" | titlecase | shorten: 28:"..." }}
    </span>
  </mat-card-title>
  <div class="d-flex 2-col">
    <app-avatar
      class="flex-half"
      [fv]="fn"
      [baseSize]="75"
      [showPills]="showPills"
    ></app-avatar>
    <div class="flex-half">
      <app-field
        class="mb-2"
        label="passport"
        value="{{ passport ?? 'ISSUE' | uppercase }} "
        [valueColor]="passportColor"
        [matTooltip]="passportTooltip"
      ></app-field>
      <app-field
        label="date of birth"
        value="{{ (dateOfBirth | date: 'mediumDate') ?? 'ISSUE' }}"
        [valueColor]="dobColor"
        [matTooltip]="dobTooltip"
      >
      </app-field>
    </div>
  </div>
</div>
