<ng-container *ngIf="visas$ | async as visas">
  <ng-container *ngIf="visas.length; else emptyCollectionButtonStyle">
    <mat-expansion-panel
      class="flex-fill mt-3 panel-200-mh"
      #visaPanel="matExpansionPanel"
      id="fv-view-visa-panel"
      *ngIf="visas.length"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="d-flex justify-content-between py-2">
          <span>Visas</span>
          <mat-icon
            svgIcon="card-text-outline"
            [matBadge]="visas.length"
            matBadgeSize="small"
            matBadgeColor="accent"
          ></mat-icon>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-divider class="panel-header-divider"></mat-divider>
      <div class="visas-body">
        <ng-container *ngFor="let item of visas; index as i">
          <div class="content row">
            <div class="col">
              <div class="row">
                <div class="col-6">
                  <dl>
                    <dt>Visa Number</dt>
                    <dd>{{ item.number | default }}</dd>
                    <dt>Visa Type</dt>
                    <dd style="word-wrap: break-word">
                      {{ item.type | default }}
                    </dd>
                  </dl>
                </div>
                <div class="col-6">
                  <dl style="line-break: anywhere">
                    <dt>Issue Location</dt>
                    <dd>
                      {{ item.issueLocation | default }}
                    </dd>
                    <dt>Expiration Date</dt>
                    <dd>
                      {{ item.expirationDate | militaryDate | default }}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <mat-divider
              [vertical]="true"
              class="panel-vertical-divider"
            ></mat-divider>
            <div
              class="col-2 d-flex justify-content-center align-items-center"
              style="flex-direction: column"
            >
              <button
                *ngIf="editable"
                mat-icon-button
                (click)="updateVisa(item)"
                matTooltip="Edit Visa"
              >
                <mat-icon svgIcon="square-edit-outline"></mat-icon>
              </button>

              <div class="flex-fill"></div>
              <div *ngIf="i === 0 && editable" class="mb-2">
                <button
                  mat-mini-fab
                  matTooltip="Add Visa"
                  class="bg-warning"
                  style="color: white"
                  (click)="createVisa()"
                >
                  <mat-icon svgIcon="plus-circle-outline"></mat-icon>
                </button>
              </div>
            </div>
          </div>
          <mat-divider
            *ngIf="i + 1 < visas.length"
            class="panel-header-divider"
          ></mat-divider>
        </ng-container>
      </div>
    </mat-expansion-panel>
  </ng-container>
  <ng-template #emptyCollectionButtonStyle>
    <button
      class="mt-3 w-100"
      style="height: 48px"
      mat-raised-button
      matTooltip="Add New Visa"
      color="primary"
      (click)="createVisa()"
      [disabled]="!editable"
    >
      <div class="d-flex align-items-center">
        <span>Add Visa</span>
        <mat-icon class="ml-2" svgIcon="card-text-outline"></mat-icon>
      </div>
    </button>
  </ng-template>
</ng-container>
