import {
  INGXLoggerConfig,
  INGXLoggerMetadata,
  NgxLoggerLevel,
  NGXLoggerServerService,
} from 'ngx-logger';
import { Injectable } from '@angular/core';
import {
  HttpBackend,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { AWSCloudWatchProvider } from '@aws-amplify/core';
import { InputLogEvent } from '@aws-sdk/client-cloudwatch-logs';
import { AuthService } from '@app/auth/auth.service';

import { fromError } from 'stacktrace-js';

import { pick } from 'lodash';
import { User } from '@shared/models';
import * as UAParser from 'ua-parser-js';
import { IResult } from 'ua-parser-js';
import { v4 as uuidv4 } from 'uuid';
import { AppConfigService } from '@shared/services/app-config.services';
import { Observable, Subject } from 'rxjs';
import { environment } from '@environments/environment';
import { bufferTime, filter, share, switchMap } from 'rxjs/operators';

const LOG_LEVELS: { [key: string]: string } = {
  [NgxLoggerLevel.FATAL]: 'FATAL',
  [NgxLoggerLevel.ERROR]: 'ERROR',
  [NgxLoggerLevel.WARN]: 'WARN',
  [NgxLoggerLevel.INFO]: 'INFO',
  [NgxLoggerLevel.DEBUG]: 'DEBUG',
};

@Injectable()
export class LogSenderService extends NGXLoggerServerService {
  cwProvider: AWSCloudWatchProvider;
  browserData: IResult;

  apiLogBuffer = new Subject<string>();
  apiBufferTime = 2000; // Buffer log requests to API every 2 seconds

  constructor(
    private config: AppConfigService,
    private auth: AuthService,
    private http: HttpClient,
    httpBackend: HttpBackend
  ) {
    super(httpBackend);
    this.browserData = new UAParser().getResult();

    const cwLogConfig = this.config.get('cloudwatchLog');
    const disableAPILogging = this.config.get('disableAPILogging');
    if (cwLogConfig) {
      // Send Logs via Cloudwatch if enabled
      cwLogConfig.logStreamName = this.browserId;
      this.cwProvider = new AWSCloudWatchProvider(cwLogConfig);
    } else if (!disableAPILogging) {
      // Fallback to sending logs to API
      this.apiLogBuffer
        .asObservable()
        .pipe(
          bufferTime<string>(this.apiBufferTime),
          filter((logEvents) => !!logEvents.length),
          switchMap((logEvents) => this.pushAPILog(...logEvents))
        )
        .subscribe();
    }
  }

  /**
   * Get/Generate a unique browserId for Cloudwatch Log Stream
   */
  get browserId(): string {
    if (!localStorage.getItem('citadelBrowserId')?.length) {
      localStorage.setItem('citadelBrowserId', uuidv4());
    }

    return localStorage.getItem('citadelBrowserId') as string;
  }

  _sequenceTokenPromise: Promise<any>;

  /**
   * Convert log entry to Cloudwatch Log InputLogEvent
   * and add additional useful metadata
   *
   * @param metadata INGXLoggerMetadata
   * @param config INGXLoggerConfig
   */
  async convertLog(
    metadata: INGXLoggerMetadata,
    config: INGXLoggerConfig
  ): Promise<string> {
    const logContent: any = { ...metadata };

    logContent.context = config.context;
    logContent.level = LOG_LEVELS[logContent.level] ?? 'INFO';
    logContent.browserData = this.browserData;
    const user = this.auth.getUser();
    if (user) logContent.user = pick<User>(user, 'id', 'username', 'roles');
    logContent.href = window.location.href;

    if (logContent.message instanceof HttpErrorResponse) {
      const response = logContent.message as HttpErrorResponse;
      logContent.response = response;
      logContent.message = response.message
        ? response.message
        : response.toString();
    } else if (logContent.message instanceof Error) {
      const error = logContent.message as Error;
      logContent.stack = await fromError(error);
      logContent.message = error.message ? error.message : error.toString();
    }

    if (logContent.stack?.length) {
      Object.assign(logContent, logContent.stack[0]);
    }

    return JSON.stringify(logContent);
  }

  /**
   * Push NGXLogger log message to Cloudwatch Logs
   *
   * @param metadata INGXLoggerMetadata
   * @param config INGXLoggerConfig
   */
  async pushCWLog(message: string) {
    // Ensure token loaded to avoid this bug https://github.com/aws-amplify/amplify-js/issues/10172
    if (!this._sequenceTokenPromise) {
      this._sequenceTokenPromise = (
        this.cwProvider as any
      )._getNextSequenceToken();
    }
    await this._sequenceTokenPromise;
    const event: InputLogEvent = {
      message,
      timestamp: Date.now(),
    };
    this.cwProvider.pushLogs([event]);
  }

  pushAPILog(...messages: string[]): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/log`, messages);
  }

  public async sendToServer(
    metadata: INGXLoggerMetadata,
    config: INGXLoggerConfig
  ) {
    const event = await this.convertLog(metadata, config);
    if (this.cwProvider) await this.pushCWLog(event);
    else this.apiLogBuffer.next(event);
  }
}
