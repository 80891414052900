import {
  Component,
  Input,
  OnInit,
  Injector,
  OnDestroy,
  ViewChild,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FilterParams } from '@app/shared/models';
import { Subject, ReplaySubject, Subscription } from 'rxjs';
import { Country } from '@shared/models/country.model';
import { CountryService } from '@shared/services/country.service';
import { startWith, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { BaseControlComponent } from '../base-control.component';
import {
  FloatLabelType,
  MatFormFieldAppearance,
} from '@angular/material/form-field';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'app-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountrySelectComponent
  extends BaseControlComponent<string | null>
  implements OnInit, OnDestroy
{
  @Input() placeholder = 'Country';
  @Input() includeOrgs: boolean = false;
  @Input() id: string;
  @ViewChild('countrySelect', { static: true }) countrySelect: MatSelect;

  @Input() multiple: boolean = false;
  @Input() excludeUS: boolean = false;
  @Input() floatLabel: FloatLabelType = 'auto';
  @Input() label: string = 'Country';
  @Input() filterLabel: string = 'Search';
  @Input()
  appearance: MatFormFieldAppearance = 'outline';
  filteredCountries = new ReplaySubject<Country[]>(1);

  /** control for the search input value */
  searchCtrl: FormControl = new FormControl();

  public searching = false;

  private destroy$: Subject<void> = new Subject<void>();

  private _searchSub$ = Subscription.EMPTY;

  constructor(
    public injector: Injector,
    private countryService: CountryService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.searchCtrl.valueChanges
      .pipe(startWith(''), takeUntil(this.destroy$))
      .subscribe((search) => this.search(search));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  search(searchVal?: string) {
    const params: FilterParams = new FilterParams({
      sort: {
        active: 'name',
        direction: 'asc',
      },
    });

    if (this.includeOrgs) params.includeNonCountryOrgs = true;
    if (this.excludeUS) params.us = false;

    const search = searchVal?.trim();
    if (search?.length) params.search = search;

    this.searching = true;
    this._searchSub$.unsubscribe();
    this._searchSub$ = this.countryService
      .find(params)
      .pipe(takeUntil(this.destroy$))
      .subscribe((filtered) => {
        this.searching = false;
        this.filteredCountries.next(filtered);
      });
  }

  trackBy(idx: number, country: Country): string {
    return country?.id;
  }

  clearValue(evt?: MouseEvent) {
    evt?.stopPropagation();

    this.value = null;
    this.emitChangeEvent();
  }
}
