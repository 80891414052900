<ng-container *ngIf="fv.nationalIds?.length; else emptyCollectionButtonStyle">
  <mat-expansion-panel
    class="flex-fill mt-3 panel-200-mh"
    #nationalIdPanel="matExpansionPanel"
    id="fv-view-national-id-panel"
  >
    <mat-expansion-panel-header>
      <mat-panel-title class="d-flex justify-content-between py-2">
        <span>National IDs</span>
        <mat-icon
          svgIcon="id-card"
          [matBadge]="fv.nationalIds?.length || 0"
          matBadgeSize="small"
          matBadgeColor="accent"
        ></mat-icon>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-divider class="panel-header-divider"></mat-divider>
    <div class="national-id-body">
      <ng-container *ngFor="let item of fv.nationalIds; index as i">
        <div class="content row">
          <div class="col">
            <div class="row">
              <div class="col-6">
                <dl>
                  <dt>Country</dt>
                  <dd class="d-flex align-items-center">
                    <mat-icon
                      class="mr-1"
                      *ngIf="
                        item.issuingCountryCode
                          | country: 'alpha2' as countryCode
                      "
                      [flagIcon]="countryCode"
                    ></mat-icon>
                    {{ item.issuingCountryCode | country }}
                  </dd>
                </dl>
              </div>
              <div class="col-6">
                <dl style="line-break: anywhere">
                  <dt>National ID</dt>
                  <dd>
                    {{ item.nationalId | default }}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <mat-divider
            [vertical]="true"
            class="panel-vertical-divider"
          ></mat-divider>
          <div
            class="col-2 d-flex justify-content-center align-items-center"
            style="flex-direction: column"
          >
            <button
              *ngIf="editable"
              mat-icon-button
              (click)="openNatlIdDialog(item.id)"
              matTooltip="Edit National ID"
            >
              <mat-icon svgIcon="square-edit-outline"></mat-icon>
            </button>

            <div class="flex-fill"></div>
            <div *ngIf="i === 0 && editable" class="mb-2">
              <button
                mat-mini-fab
                matTooltip="Add National Id"
                class="bg-warning"
                style="color: white"
                (click)="openNatlIdDialog()"
              >
                <mat-icon svgIcon="plus-circle-outline"></mat-icon>
              </button>
            </div>
          </div>
        </div>
        <mat-divider
          *ngIf="fv.nationalIds && i + 1 < fv.nationalIds.length"
          class="panel-header-divider"
        ></mat-divider>
      </ng-container>
    </div>
  </mat-expansion-panel>
</ng-container>
<ng-template #emptyCollectionButtonStyle>
  <button
    class="mt-3 w-100"
    style="height: 48px"
    mat-raised-button
    matTooltip="Add National Id"
    color="primary"
    (click)="openNatlIdDialog()"
    [disabled]="!fv.isEditable"
  >
    <div class="d-flex align-items-center">
      <span>Add National ID</span>
      <mat-icon class="ml-2" svgIcon="id-card"></mat-icon>
    </div>
  </button>
</ng-template>
