import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { Visa, VisaTypes } from '@app/shared/models';
import {
  AbstractControl,
  NgForm,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { BaseControlComponent } from '@app/shared/controls/base-control.component';
import { hasValues } from '@app/shared/helpers/has-values';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'app-visa',
  templateUrl: './visa.component.html',
  styleUrls: ['visa.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: VisaComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => VisaComponent),
      multi: true,
    },
  ],
})
export class VisaComponent
  extends BaseControlComponent<Visa>
  implements Validator, OnInit
{
  @Input() showClearButton: boolean = true;
  @ViewChild('visaForm')
  visaForm: NgForm;
  visaTypes = VisaTypes;
  appearance: MatFormFieldAppearance = 'outline';
  isVisa: boolean = false;
  isRequiredVisa: boolean;
  visaEntries: any = ['Single', 'Multiple'];
  gender: any = ['Male', 'Female'];
  visaPattern = /[0-9]{8}|[a-zA-Z]{1}[0-9]{7}/;
  onValidationChange: any = () => {};

  constructor() {
    super();
  }

  addVisa() {
    this.emitChangeEvent();
  }

  getEmitValue(): Visa | null {
    return this.hasVisaData() ? this.value : null;
  }

  hasVisaData() {
    return hasValues(this.value);
  }

  ngOnInit() {
    this.value = {};
  }

  writeValue(_value: Visa) {
    super.writeValue(_value);
    this.value = this.value || {};
  }

  validate(_control: AbstractControl): ValidationErrors | null {
    return this.visaForm?.valid ? null : { visa: true };
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }
}
