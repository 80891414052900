import { AuthConfig } from 'angular-oauth2-oidc';
import { InjectionToken } from '@angular/core';
import { Role } from '@shared/models';
import { AWSCloudWatchProviderOptions } from '@aws-amplify/core/src/types/types';
import { NgxLoggerLevel } from 'ngx-logger';

export const APP_CONFIG: InjectionToken<IEnvironment> =
  new InjectionToken<IEnvironment>('Application Configuration');

export interface CMTSettings {
  /**
   * CMT Integration script URL
   */
  cmtIntegrationUrl: string;
  /**
   * CMT hosted assets path
   */
  cmtTemplatePath: string;
  /**
   * Auth Claim value to use for CMT Classified By
   */
  cmtClassifiedByAuthClaim: string;
}

export interface IEnvironment {
  /**
   * Load Angular in production mode
   */
  production: boolean;
  /**
   * URL path to API services
   */
  apiUrl: string;
  /**
   * Support Email address
   */
  supportEmail?: string;
  /**
   * Support Phone Number
   */
  supportPhone?: string;
  /**
   * Support start time as hours for live help
   */
  supportStartTime?: number;
  /**
   * Support end time as hours for live help
   */
  supportEndTime?: number;
  /**
   * Cognito Identity Pool Id (for Cognito Auth only)
   */
  identityPoolId?: string;
  /**
   * Cognito User Pool Id (for Cognito Auth only)
   */
  userPoolId?: string;
  /**
   * Cognito client Id (for Cognito Auth only)
   */
  userPoolWebClientId?: string;
  /**
   * Enable/Disable MFA authentication (for Cognito Auth only)
   */
  mfa?: boolean;
  /**
   * AWS region (for Cognito Auth only)
   */
  awsRegion?: string;
  /**
   * Application Overall Classification
   */
  classification?: string;
  /**
   * Disable User Administration pages (ie when OAuth enabled)
   *
   * @deprecated use 'limitedUserAdmin' field instead
   */
  disableUserAdmin?: boolean;
  /**
   * Limited User Administration (ie when OAuth enabled)
   */
  limitedUserAdmin?: boolean;
  /**
   * Use ID instead of Access token for Auth (Default: true)
   */
  useIdToken?: boolean;
  /**
   * User Idle Time in seconds until Timeout Warning (Default: 900 secs = 15 mins)
   */
  autoLogoutIdleTime?: number;
  /**
   * User Idle Timeout in seconds for warning countdown (Default: 30 secs)
   */
  autoLogoutIdleTimeout?: number;
  /**
   * Disable Auto Logout
   */
  disableAutoLogout?: boolean;
  /**
   * Show Role Selector for Users
   */
  showRoleSelector?: boolean;
  /**
   * Enable OAuth authentication mode
   */
  oauth?: boolean;
  /**
   * OAuth configuration settings
   */
  oauthConfig?: AuthConfig;
  /**
   * Path to externally loaded configuration settings
   */
  configJson?: string;
  /**
   * Path to externally loaded country data
   */
  countries?: string;
  /**
   * disables child object DELETE UI functionality when 'true', enables it when
   * 'false'
   */
  restrictDelete?: boolean;
  /**
   * Available User Roles in UI (for System Admin)
   */
  roles?: Role[];
  /**
   * Use in API Dev Mode to set your Role
   */
  devUserRole?: Role;
  /**
   * Link to Help Page File
   */
  helpLink: string;
  /**
   * Logger Service log level min for console (default: ERROR)
   */
  consoleLogLevel?: NgxLoggerLevel;
  /**
   * Logger Service log level min for server (default: INFO)
   */
  serverLogLevel?: NgxLoggerLevel;
  /**
   * Link to Screening Code Help PDF File
   */
  screeningCodeLink: string;
  /**
   * Config if you like to enable Cloudwatch Logging
   * (requires credentials or cognito identity pool with permissions)
   */
  cloudwatchLog?: AWSCloudWatchProviderOptions;
  /**
   * Disable Fallback to API Logging if Cloudwatch is not configured (default: false)
   */
  disableAPILogging?: boolean;
  /**
   * Audit log user activity (requires cloudwatch logs)
   */
  auditUserActivity?: boolean;
  /**
   * CMT related settimgs
   */
  cmt?: CMTSettings;
  /**
   * Toggle to turn on/off display of Automated Screening Features
   */
  automatedScreeningEnabled?: boolean;
  /**
   * Average Time in Minutes, that it takes to get a Screening Response from EVC
   */
  averageScreeningResponseTime?: number;
  /**
   * Flag to toggle FARs being required for an FN to be screening eligible
   */
  farScreeningEligibility?: boolean;
  /**
   * Enable Display of data deletion filters (default: true)
   */
  dataDeletionFiltersEnabled?: boolean;
  /**
   * Enable Lowside org admin user approvals (default: false)
   */
  orgAdminApprovalsEnabled?: boolean;
  /**
   * Manages high environment specific functionality such as:
   * Screening Codes & Display (screeningCodeEnabled)
   */
  highEnvFeatures?: boolean;
  /**
   * Manages low environment specific functionality
   * Organization Edit (organizationEditEnabled)
   * Deletion Message Checks (deletionMessageCheckEnabled)
   */
  lowEnvFeatures?: boolean;
}
