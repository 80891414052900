<form #diplomaticForm="ngForm" id="diplomaticId">
  <div class="row">
    <div class="col-4">
      <!-- Diplomatic ID Number -->
      <mat-form-field appearance="outline">
        <mat-label>Diplomatic ID Number</mat-label>
        <input
          [disabled]="!editable"
          id="diplomaticNumber"
          matInput
          [required]="hasDiplomaticPassportData() && editable"
          #diplomaticNumberControl="ngModel"
          [(ngModel)]="value.documentId"
          name="diplomaticNumber"
          maxlength="25"
          aria-label="diplomatic number"
          placeholder="Enter Diplomatic ID Number"
          (ngModelChange)="emitChangeEvent()"
        />
        <mat-error>Please enter a valid Diplomatic ID</mat-error>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field [appearance]="appearance">
        <mat-label>Diplomatic ID Issue Date</mat-label>
        <input
          id="diplomaticPassportIssueDate"
          #diplomaticPassportIssueDateControl
          [disabled]="!hasDiplomaticPassportData() || !editable"
          [(ngModel)]="value.issueDate"
          (ngModelChange)="emitChangeEvent()"
          matInput
          [max]="today"
          [min]="minIssueDate"
          [matDatepicker]="diplomaticPassportIssueDatePicker"
          name="issueDate"
          aria-label="issue date"
          placeholder="MM/DD/YYYY"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="diplomaticPassportIssueDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker
          #diplomaticPassportIssueDatePicker
          [disabled]="!hasDiplomaticPassportData() || !editable"
        ></mat-datepicker>
        <mat-error> Please add a valid date</mat-error>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field [appearance]="appearance">
        <mat-label>Diplomatic ID Expiration</mat-label>
        <input
          id="diplomaticPassportExpirationDate"
          #diplomaticPassportExpirationDateControl
          matInput
          [disabled]="!hasDiplomaticPassportData() || !editable"
          [(ngModel)]="value.expirationDate"
          (ngModelChange)="checkDiplomaticPassport()"
          [matDatepicker]="diplomaticPassportExpirationDatePicker"
          [min]="value.issueDate"
          name="expirationDate"
          aria-label="expiration date"
          placeholder="MM/DD/YYYY"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="diplomaticPassportExpirationDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker
          #diplomaticPassportExpirationDatePicker
          [disabled]="!hasDiplomaticPassportData() || !editable"
        ></mat-datepicker>
        <mat-error>Please select an expiration date</mat-error>
      </mat-form-field>
    </div>
  </div>
</form>
