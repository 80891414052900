import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ForeignVisitor, FAR } from '@shared/models';

@Component({
  selector: 'app-fv-list-item',
  templateUrl: './fv-list-item.component.html',
  styleUrls: ['./fv-list-item.component.scss'],
})
export class FvListItemComponent {
  @Input() deletable: boolean = false;
  @Input() item: ForeignVisitor;
  @Input() size: 'xsmall' | 'small' | 'medium' | 'large' = 'medium';
  @Input() showResults: boolean = false;
  @Input() showRemove: boolean = false;
  @Input() showGoTo: boolean = false;
  @Input() showOwningOrg: boolean = true;
  @Input() openNewWindow: boolean = false;
  @Input() highlighted: boolean = false;
  @Output() removed = new EventEmitter<ForeignVisitor>();

  openLink(id: string) {
    window.open('/fvs' + id);
  }
}
