import { Component, Input } from '@angular/core';
import { FarLocation } from '@shared/models';

@Component({
  selector: 'app-far-event',
  templateUrl: './far-event.component.html',
  styleUrls: ['./far-event.component.scss'],
})
export class FarEventComponent {
  @Input() showLocation = true;
  @Input() contentTrimLength = 25;
  @Input() primaryLocation?: FarLocation = {};
}
