<mat-list-item class="linked-item-grid py-2" [attr.aria-selected]="highlighted">
  <div class="grid-area-avatar">
    <app-avatar
      matListItemAvatar
      [enableBorderOutline]="true"
      [showShadow]="false"
      [size]="size"
      [fv]="item"
    ></app-avatar>
  </div>
  <div matListItemMeta class="grid-area-details">
    <div matListItemLine>
      <span
        matListItemTitle
        [matTooltip]="item | fullname: 'surnameFirst' | default"
        >{{
          item | fullname: "surnameFirst" | shorten: 20:"..." | default
        }}</span
      >
      <mat-icon *ngIf="item.vip" color="accent" class="vip-icon"
        >stars</mat-icon
      >
    </div>
    <div matListItemLine>
      <mat-icon
        *ngIf="!item.dateOfBirth"
        class="md-16 unknown-icon"
        color="warn"
        matTooltip="DOB Required for Screening"
      >
        warning </mat-icon
      ><span *ngIf="item.dateOfBirth">{{
        item.dateOfBirth | age | default
      }}</span
      >/
      <span
        [matTooltip]="item.gender ? (item.gender | titlecase) : 'Gender Unkown'"
        >{{ item.gender | titlecase | default }}</span
      >
    </div>
    <div matListItemLine>
      <mat-icon
        class="md-16 unknown-icon"
        color="warn"
        *ngIf="
          !(item | currentPassport)?.number ||
          !(item | currentPassport)?.issuingCountryCode
        "
        matTooltip="Passport Required for Screening"
      >
        warning </mat-icon
      >Passport: {{ (item | currentPassport)?.number | default }}
    </div>
  </div>
  <div matListItemMeta class="grid-area-screening">
    <div
      *ngIf="item && item.deletionDate"
      class="screening-area-delete"
      matListItemMeta
    >
      <span> Deletion In: {{ item.deletionDate | daysUntil }} Days </span>
    </div>
    <app-screening-indicator
      class="screening-area-status"
      *ngIf="item.latestScreening as screening"
      [id]="'screening-indicator-' + item.id"
      [screening]="screening"
      [hasMenu]="true"
      [display]="
        screening.result && screening.status !== 'Error' ? 'result' : 'status'
      "
    ></app-screening-indicator>
    <div
      class="screening-area-org"
      matListItemLine
      *ngIf="item.auditMetadata && showOwningOrg"
    >
      <ng-container
        *ngIf="
          item.auditMetadata?.owningOrganizationShortName;
          else noShortName
        "
      >
        <span
          matTooltip="{{
            item.auditMetadata?.owningOrganizationName | default
          }}"
        >
          Owning Org:
          {{ item.auditMetadata?.owningOrganizationShortName | default }}
        </span>
      </ng-container>
      <ng-template #noShortName>
        <button
          mat-button
          [matMenuTriggerFor]="longNameMenu"
          [stopPropagation]="['click']"
          matTooltip="Click to View More"
        >
          <mat-icon [svgIcon]="'sitemap'" matSuffix> </mat-icon>
          Owning Org
        </button>
        <mat-menu #longNameMenu>
          <div mat-menu-item>
            {{ item.auditMetadata.owningOrganizationName | default }}
          </div>
        </mat-menu>
      </ng-template>

      <div class="d-inline text-truncate"></div>
    </div>
    <div class="screening-area-buttons">
      <ng-container *restrictDelete>
        <ng-container *ngIf="deletable">
          <button
            mat-icon-button
            *ngIf="showRemove"
            [id]="'far-fv-remove-' + item.id"
            color="warn"
            matTooltip="Remove Foreign National"
            (click)="removed.emit(item)"
          >
            <mat-icon class="mat-24 align-self-center"
              >remove_circle_outline</mat-icon
            >
          </button>
        </ng-container>
      </ng-container>
      <button
        mat-icon-button
        *ngIf="showGoTo"
        [id]="'far-fv-view-' + item.id"
        matTooltip="Go To Foreign National's Profile"
        [routerLink]="['/fv', item.id]"
        color="primary"
      >
        <mat-icon class="mat-24"> open_in_new </mat-icon>
      </button>
    </div>
  </div>
</mat-list-item>
