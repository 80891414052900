import {
  OnInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { NgForm, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Address, Role } from '@app/shared/models';
import { BaseControlComponent } from '../base-control.component';
import { LookupService, UtilityConfigService } from '@app/shared/services';
import { AddressService } from '@app/shared/services/address.service';
import { forkJoin, take } from 'rxjs';
import { hasValues } from '@app/shared/helpers/has-values';

/**
 * This component accepts a value from a parent form.  An Angular form directive
 * is used for its valueChanges(), and validation methods.
 * Address component is a configurable component.
 * ## configurations
 *
 * appearance: "fill" | "legacy" | "standard" | "outline" = 'outline'
 *
 * columns: 1 | 2 | 3 | 4 | 5 | 6 = 3
 *
 * legend: string = 'Address'
 *
 * ## example
 * ```html
 * <app-address [(ngModel)]="fv.address" name="address"><app-address>
 * ```
 */
@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: AddressComponent },
  ],
})
export class AddressComponent
  extends BaseControlComponent<Partial<Address>>
  implements OnInit
{
  @Input() countryPlaceholder: string = 'Select Country';
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() suggestionsEnabled: boolean = false;
  @Input() legend: string = '';
  @Input() columns: 1 | 2 | 3 | 4 | 5 | 6 = 3;
  @Output() clear = new EventEmitter<void>();
  Role = Role;

  @ViewChild('form') form: NgForm;
  touched = false;

  options: any[] = [];

  constructor(
    private lookupService: LookupService,
    private utilityConfigService: UtilityConfigService,
    private addressService: AddressService
  ) {
    super();
  }

  public countryList: any = {};

  ngOnInit(): void {
    forkJoin([
      this.lookupService.getCountries(),
      this.utilityConfigService.getCountryData(),
    ]).subscribe(
      ([countries, countriesAddressData]) => {
        for (const country of countries) {
          let found = false;
          for (const cad of countriesAddressData) {
            if (country.lookup_short_name === cad.code) {
              cad.threeDigitCode = country.lookup_cd;
              cad.zone = cad.labels.zone;
              cad.postalCode = cad.labels.postalCode;
              delete countriesAddressData.labels;
              delete countriesAddressData.formatting;
              delete countriesAddressData.optionalLabels;
              this.countryList[country.lookup_cd as string] = cad;
              found = true;
            }
          }
          if (!found) {
            let newCountry = new CountryModel(
              country.lookup_data,
              country.lookup_short_name as string,
              country.lookup_cd as string
            );
            this.countryList[country.lookup_cd as string] = newCountry;
          }
        }
      },
      (err) => {
        console.error('Error - Unable to Retrieve Country Data');
      }
    );
  }

  value: Partial<Address> = {};

  get cols() {
    return this.columns <= 6 ? `row-cols-${this.columns}` : '';
  }

  writeValue(value: Address): void {
    this.value = value || {};
  }

  resetForm(): void {
    this.form.reset({});
    this.emitChangeEvent();
  }
  getEmitValue(): Partial<Address> | null {
    return this.hasData() ? this.value : null;
  }

  // helper function to determine if any of the keys in this.value
  // are empty
  hasData(): boolean {
    return hasValues(this.value);
  }

  checkCountry() {
    if (!this.value.countryCode && this.value.stateProvince)
      this.value.stateProvince = '';
  }

  updateLocalityField() {
    this.value.stateProvince = '';
    // NOTE: Remove when removing the other code for the value's province.
    this.emitChangeEvent();
  }

  getAddressSuggestion(line1: any, countryCode?: string) {
    if (line1?.length > 2 && this.suggestionsEnabled) {
      this.addressService
        .getAddressSuggestions(line1, countryCode)
        .pipe(take(1))
        .subscribe((res) => {
          this.options = res;
        });
    }
  }

  getFinalAddress(placeId: string) {
    this.addressService
      .getSelectedAddress(placeId)
      .pipe(take(1))
      .subscribe((res) => {
        this.value.line1 = '';
        if (res.addressNumber) this.value.line1 = res.addressNumber + ' ';
        if (res.street) this.value.line1 += res.street;
        this.value.postalCode = res.postalCode;
        this.value.stateProvince = res.region;
        this.value.city = res.municipality;
        this.value.latitude = res.latitude;
        this.value.longitude = res.longitude;
        this.value.countryCode = res.country;
        this.emitChangeEvent();
      });
  }
}

export class CountryModel {
  name: string;
  code: string;
  threeDigitCode: string;
  provinceKey: string;
  postalCode: string;
  zone: string;
  zones: string[];

  //Country Name, Country 2 Digit Code, Country Three Digit Code
  constructor(name: string, code: string, threeDigitCode: string) {
    this.name = name;
    this.code = code;
    this.threeDigitCode = threeDigitCode;
    this.provinceKey = 'PROVINCE';
    this.postalCode = 'Postal Code / ZIP Code';
    this.zone = 'Province';
    this.zones = [];
  }
}
